@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/drg2rte.css');

body,
#root {
  background: linear-gradient(
    180deg,
    #f0eeff 18.76%,
    #ffde79 75.53%,
    #a2ff30 90%
  );
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  font-weight: 400;
}

@layer utilities {
  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .h-screen-min-90 {
    height: calc(100vh - 90px);
    height: calc(100dvh - 90px);
  }

  .h-screen-min-70 {
    height: calc(100vh - 70px);
    height: calc(100dvh - 70px);
  }

  .h-screen-min-110 {
    height: calc(100vh - 110px);
    height: calc(100dvh - 110px);
  }

  .h-screen-min-160 {
    height: calc(100vh - 160px);
    height: calc(100dvh - 160px);
  }

  .max-h-screen-min-160 {
    max-height: calc(100vh - 160px);
    max-height: calc(100dvh - 160px);
  }

  .h-screen-min-180 {
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
  }

  .h-screen-min-200 {
    height: calc(100vh - 200px);
    height: calc(100dvh - 200px);
  }

  .trans-y-screen-min-90 {
    transform: translateY(calc(100vh - 90px));
    transform: translateY(calc(100dvh - 90px));
  }

  .trans-y-screen-min-75 {
    transform: translateY(calc(100vh - 75px));
    transform: translateY(calc(100dvh - 75px));
  }

  .trans-y-screen-min-70 {
    transform: translateY(calc(100vh - 70px));
    transform: translateY(calc(100dvh - 70px));
  }

  .trans-y-screen-min-110 {
    transform: translateY(calc(100vh - 110px));
    transform: translateY(calc(100dvh - 110px));
  }

  .trans-y-screen-min-160 {
    transform: translateY(calc(100vh - 160px));
    transform: translateY(calc(100dvh - 160px));
  }

  .trans-y-screen-min-180 {
    transform: translateY(calc(100vh - 180px));
    transform: translateY(calc(100dvh - 180px));
  }

  .trans-y-screen-min-200 {
    transform: translateY(calc(100vh - 200px));
    transform: translateY(calc(100dvh - 200px));
  }

  .-trans-y-screen-min-500 {
    transform: translateY(calc(-100vh + 500px));
    transform: translateY(calc(-100dvh + 500px));
  }
}

.about-big {
  @apply text-about-big-mobile md:text-about-big leading-[33px] md:leading-[43px] font-normal;
}

.about-h3 {
  @apply text-about-big-mobile md:text-about-big leading-[33px] md:leading-[50px] font-normal;
}

.page-title {
  @apply text-page-title-sm-mobile sm:text-page-title-mobile md:text-page-title leading-[51px] md:leading-[88px] font-[300] mr-[110px] md:mr-[10px];
}

.about-p-sm {
  @apply text-[20px] leading-[23px] md:text-p-md md:leading-[28px];
}

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
